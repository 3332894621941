import React from 'react'
import moment from 'moment'

import { navigate } from 'react-big-calendar/lib/utils/constants'

class CustomAgendaView extends React.Component {
  render() {
    let { date, ...props } = this.props
    let range = CustomAgendaView.range(date)
    return <div style={{ height: 700 }}>{table(this.props.events, this.props.localizer.messages)}</div>
  }
}

// Day.propTypes = {
//   date: PropTypes.instanceOf(Date).isRequired,
// }

const table = (data, messages) => {
  allEvents = [];
  data.length>0 &&
    data.map(item => eventList(item, messages))

  let agendaTable = (
  <div className="rbc-agenda-view">
    <table className="rbc-agenda-table">
        <thead>
            <tr>
                <th className="rbc-header">Fecha</th>
                <th className="rbc-header">Horario</th>
                <th className="rbc-header">Descripción del evento</th>
                <th className="rbc-header">Link</th>
            </tr>
        </thead>
        <tbody>
            {
              allEvents.sort((a, b) => a.date > b.date ? 1 : -1).map(d=>eventDetail(d))
            }
        </tbody>
    </table>
  </div>)
  return agendaTable;
}

var allEvents = [];

const eventList = (item, messages) => {
  var start = moment(item.startDate, "YYYY-MM-DD");
  var end = moment(item.endDate, "YYYY-MM-DD");
  var dates = []; var i = 0;
 
  var multipleDays = end.diff(start,'days');
  if(multipleDays>0){
    var now = start.clone();
    while (now.isSameOrBefore(end)) {
      if(i==0){
        var text = item.allDay ? messages.allDay : moment(item.startDate).format("HH:mm");
      } else if (i==multipleDays){
        var text = item.allDay ? messages.allDay : moment(item.endDate).format("HH:mm");
      } else {
        var text = messages.allDay;
      }
      i++;
      var obj = {date: now.format("ddd MMM DD") , event: item.name, time: text, link: item.urlString?item.urlString:false , type: item.diaryTypeId}
      allEvents.push(obj)
      now.add(1, 'days');
    }
  } else {
    var obj = {date: start.format("ddd MMM DD"), event: item.name, time: item.allDay ? messages.allDay : moment(item.startDate).format("HH:mm")+' - '+moment(item.endDate).format("HH:mm"), link: item.urlString?item.urlString:false, type: item.diaryTypeId}
    allEvents.push(obj)
  }
}

const eventDetail = (item) => {

  let myevent = (
    <tr key={item.companyDiaryId}>
        <td className="rbc-agenda-date-cell">{item.date}</td>
        <td className="rbc-agenda-time-cell">
            <span className="rbc-agenda-time-cell">{item.time}</span>
        </td>
        <td className="rbc-agenda-event-cell">
          <span className={item.type===0?"agenda-event":"agenda-birthday"}>
            <i class="fas fa-circle"> </i> &nbsp;
          </span>
          {
            item.link ?
              <a href={item.link} target='_blank'>
                {item.event}
              </a>
              :
              <span>{item.event}</span>
          }
        </td>
        <td className="rbc-agenda-event-cell">
          {
            item.link &&
              <a href={item.link} target='_blank'>
                <i class="fas fa-link"></i>
              </a>
          }
        </td>
    </tr>
  )
  return myevent;
}

CustomAgendaView.range = date => {
  const range = []
  
  let start = moment(date).startOf("day").format("YYYY-MM-DD");
  let end = moment(date).endOf("day").add(1, "month").format("YYYY-MM-DD");
  
  range.push(start)
  range.push(end)

  return range;
}

CustomAgendaView.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return moment(date).add(-1, "month").format("YYYY-MM-DD");

    case navigate.NEXT:
      return moment(date).add(1, "month").format("YYYY-MM-DD");

    default:
      return date
  }
}

CustomAgendaView.title = (date) => {
  let start = moment(date).startOf("day").format("DD/MM/YY");
  let end = moment(date).endOf("day").add(1, "month").format("DD/MM/YY");
  return `Agenda: ${start} - ${end}`
}

export default CustomAgendaView
import React, { useEffect, useState, useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useFrontDiary } from "../../services/diary.service";
import  CustomAgendaView  from "./diaryAgenda";

export function DiaryCalendar() {
  const [calendarDate, setCalendarDate] = useState({ date: null });
  const localizer = momentLocalizer(moment);
  const [diaryData, setDiaryData] = useState({ Diary: [] });
  const [currentRange, setCurrentRange] = useState({
    startDate: null,
    endDate: null,
  });

  const customEventPropGettet = (event, start, end, isSelected) => {
    if (event.diaryTypeId === 0) {
      return {
        className: "event-event",
      };
    } else
      return {
        className: "event-birthday",
      };
  };
  useEffect(() => {
    handleNavigate(new Date(), "month");
  }, []);
  
  useEffect(() => {
    getCompanyDiary();
  }, [currentRange]);

  async function handleNavigate(date, view) {
    let start, end;

    setCalendarDate({ date: date });
    if (view === "month") {
      start = moment(date)
        .startOf("month")
        .startOf("week")
        .format("YYYY-MM-DD");
      end = moment(date).endOf("month").endOf("week").format("YYYY-MM-DD");
    } else if (view === "week") {
      start = moment(date).startOf("isoWeek").format("YYYY-MM-DD");
      end = moment(date).endOf("isoWeek").format("YYYY-MM-DD");
    } else if (view === "day") {
      start = moment(date).startOf("day").subtract(1, 'd').format("YYYY-MM-DD");
      end = moment(date).endOf("day").add(1, 'd').format("YYYY-MM-DD");
    } else if (view === "agenda") {
      start = moment(date).startOf("day").format("YYYY-MM-DD");
      end = moment(date).endOf("day").add(1, "month").format("YYYY-MM-DD");
    }
    var range = { startDate: start, endDate: end };
    setCurrentRange(range);
  }

  async function handleView(view) {
    let start, end;
    if (calendarDate.date) {
      if (view === "month") {
        start = moment(calendarDate.date)
          .startOf("month")
          .startOf("week")
          .format("YYYY-MM-DD");
        end = moment(calendarDate.date)
          .endOf("month")
          .endOf("week")
          .format("YYYY-MM-DD");
      } else if (view === "week") {
        start = moment(calendarDate.date)
          .startOf("isoWeek")
          .format("YYYY-MM-DD");
        end = moment(calendarDate.date).endOf("isoWeek").format("YYYY-MM-DD");
      } else if (view === "day") {
        start = moment(calendarDate.date).startOf("day").subtract(1, 'd').format("YYYY-MM-DD");
        end = moment(calendarDate.date).endOf("day").add(1, 'd').format("YYYY-MM-DD");
      } else if (view === "agenda") {
        start = moment(calendarDate.date).startOf("day").format("YYYY-MM-DD");
        end = moment(calendarDate.date)
          .endOf("day")
          .add(1, "month")
          .format("YYYY-MM-DD");
      }
      var range = { startDate: start, endDate: end };
      setCurrentRange(range);
    }
  }
  async function getCompanyDiary() {
    useFrontDiary
      .getFrontCompanyDiaryFiltered(
        currentRange.startDate,
        currentRange.endDate,
        null,
        true
      )
      .then((result) => {
        if (result) {
          result.forEach((d) => {
            d.startDate = new Date(d.startDate);
            d.endDate = new Date(d.endDate);
          });
          setDiaryData({ Diary: result });
        }
      });
  }

  async function handleEventSelected(event, e) {
    if(event?.urlString){
      var url = event.urlString;
      var win = window.open(url, '_blank');
      win.focus();
    }
  }

  const messages = {
    allDay: "Todo el día",
    previous: "<",
    next: ">",
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: "Día",
    agenda: "Agenda",
    date: "Fecha",
    time: "Horario",
    event: "Descripción del evento",
    noEventsInRange: "No hay eventos en este rango.",
  };
  const { defaultDate, views } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 1),
      views: {
        agenda: CustomAgendaView,
        month: true,
        week: true,
        day: true,
      },
    }),
    []
  )
  return (
    <Calendar
      localizer={localizer}
      events={diaryData.Diary}
      titleAccessor="name"
      allDayAccessor="allDay"
      startAccessor="startDate"
      endAccessor="endDate"
      onNavigate={handleNavigate}
      onView={handleView}
      eventPropGetter={customEventPropGettet}
      onSelectEvent={handleEventSelected}
      messages={messages}
      views={views}
      defaultView={"agenda"}
    />
  );
}

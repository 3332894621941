import { callApi, callApiFileStorage } from "./service";
import swal from "sweetalert";

export const directoryInfoService = {
  getById,
  getAllByCompany,
  insertDirectory,
  getMyDirectory,
  updateDirectory,
  deleteDirectory,
  approveDirectory,
  rejectDirectory,
  setDirectoryScore
};

async function getById(id) {
  return callApi(
    `/BackEnd/GetDirectoryInfoById?directoryInfoId=${id}`,
    "GET"
  ).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      alert(result.responseMessage);

      return null;
    }
  });
}

async function getAllByCompany(companyId, statusId) {
  let endpoint = `/BackEnd/GetDirectoryInfoList?companyId=${companyId}`;
  if (statusId) endpoint = endpoint + `&statusId=${statusId}`;
  return callApi(endpoint, "GET").then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      alert(result.responseMessage);
      return null;
    }
  });
}

async function getMyDirectory() {
  return callApi(`/BackEnd/GetMyDirectoryInfoList`, "GET").then((result) => {
        if (result.responseCode == "00") {
          return result.data;
        } else {
          return null;
        }
  });
}

async function insertDirectory(params) {
  return callApiFileStorage(
    "/BackEnd/InsertDirectoryInfo",
    "POST",
    params,
    false
  ).then((result) => {
    // console.log("RES: ", result);
    // console.log("Params: ", params);
    if (result.responseCode === "00") {
      swal({ text: "El Servicio se añadió correctamente.", icon: "success" });
    } else {
      swal({ text: result.responseMessage, icon: "error" });
    }
    return result;
  });
}

async function updateDirectory(params) {
  return callApiFileStorage(
    "/BackEnd/DirectoryInfo",
    "PUT",
    params,
    false
  ).then((result) => {
    // console.log("RES: ", result);
    // console.log("Params: ", params);
    if (result.responseCode === "00") {
      swal({ text: "El Servicio se actualizó correctamente.", icon: "success" });
    } else {
      swal({ text: result.responseMessage, icon: "error" });
    }
    return result;
  });
}

async function deleteDirectory(DirectoryInfoId) {
  return callApi(
    `/BackEnd/DeleteDirectoryInfo/${DirectoryInfoId}`,
    "DELETE"
  ).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      swal({text: result.responseMessage})
      return null;
    }
  });
}

async function approveDirectory(DirectoryInfoId) {
  return callApi(
    `/BackOffice/ApproveDirectoryInfo?DirectoryInfoId=${DirectoryInfoId}`,
    "POST"
  ).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      swal({text: result.responseMessage, icon: "error"})
      return null;
    }
  });
}

async function rejectDirectory(DirectoryInfoId) {
  return callApi(
    `/BackOffice/RejectDirectoryInfo?DirectoryInfoId=${DirectoryInfoId}`,
    "POST"
  ).then((result) => {
    if (result.responseCode === "00") {
      return result.data;
    } else {
      swal({text: result.responseMessage})
      return null;
    }
  });
}

async function setDirectoryScore(params) {
  return callApiFileStorage(
    "/BackEnd/DirectoryScore",
    "POST",
    params,
    false
  ).then((result) => {
    // console.log("RES: ", result);
    if (result.responseCode === "00") {
      swal({ text: "El Servicio se actualizó correctamente.", icon: "success" });
    } else {
      swal({ text: result.responseMessage, icon: "error" });
    }
    return result;
  });
}
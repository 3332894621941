import React, { useState } from "react";
import _default from "react-bootstrap/esm/CardGroup";
import BgEcommerce01 from "../../assets/imgs/banner_ecommerce01.png";

export function BoxListEcommerce(props) {
  return (
    <div className=" content_card_audience">
      <h4 className="text-muted text fnt_medium mb-4">
        Disfruta de productos y descuentos exclusivos
      </h4>
      <div className="row">
        <div className="col-sm-6 col-lg-4 mb-4">
          <a
            className="item_ecommerce shadows card"
            href="https://www.marketank.com.mx/todos-los-productos/"
            target={_default}
          >
            <figure>
              <img src={BgEcommerce01} alt="Ecommerce" />
            </figure>
            <h5 className="mb-0 text fnt_bold text-uppercase">Marketank</h5>
            <p className="paragraph text-muted">Marketplace</p>
          </a>
        </div>
      </div>
    </div>
  );
}

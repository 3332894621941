import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./toolDiscount.scss";

import AsidePurchase from "../../components/asidePurchase/asidePurchase";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCartQuantity } from "../../store/actions";

export const ToolDiscount = () => {
  const dispatch = useDispatch();

  const cartQuantity = useSelector((state) => state.cartQuantity);
  const [activeAside, setStateAside] = useState("");
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    dispatch(setCartQuantity());
  }, []);

  const AsidePurchaseBox = () => {
    setStateAside(activeAside === "" ? " is-show" : "");

    if (activeAside === "") {
      setCart(JSON.parse(localStorage["cartKuimby"]));
      setTotal(JSON.parse(localStorage["cartTotalKuimby"]));
    }
  };

  return (
    <Fragment>
      <div className="wrapper_tool_discount">
        <Link className="item_link_op shadows trans" to="/discount">
          <span>
            <i className="fas fa-th"></i>
          </span>
          <span>Tiendas</span>
        </Link>
        <Link className="item_link_op shadows trans" to="/shopping-history">
          <span>
            <i className="fas fa-clipboard-list"></i>
          </span>
          <span>Mis compras</span>
        </Link>
        <a className="item_link_op shadows trans" onClick={AsidePurchaseBox}>
          <span>
            <i className="fas fa-shopping-cart"></i>
          </span>
          <span>
            Carrito <span>({cartQuantity})</span>
          </span>
        </a>
      </div>
      <AsidePurchase
        activeAside={activeAside}
        onCloseClick={() => setStateAside("")}
        cart={cart}
        total={total}
        setCart={setCart}
        setTotal={setTotal}
      />
    </Fragment>
  );
};

import React, { Fragment, useEffect, useState } from "react";
import useInputSingleForm from "../components/CustonHooks/inputFormHook";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const metaPixelService = {
    trackEvent,
    saveEvent,
    customEvent,
    simpleEvent
}

const token = `EAANiR2nh8N4BACXk8A5pBrK6WAHrYe0ZA3VtjpThcM95TwIgnpkuZBzXDLZBGZBrzXZAQakDmdn1XLkviBvmKdFVkrsXcQ084ZCC9LyEeOUe5jatgZCbAY4Insu9qdLZCQWOYW5ZATltEo0aX6uFpdpetWAHXFnuEVrje3hWhsKuObTN6kThnFtu5ZBCPezlngeksZD`
const pixelId = `385764100084744`

const testData = {
    "id": 0,
    "section": 'LearningCenter',
    "content_type": 'Link',
}

async function trackEvent(type, name, data) {
    window.fbq("init", pixelId);
    window.fbq(type, name, data);
}

async function saveEvent(type, name, data) {
    data = {...data, access_token: token}
    window.fbq("init", pixelId);
    window.fbq(type, name, data);
}

async function customEvent(type, name, data = null) {
    window.fbq("init", pixelId);
    if(data){
        window.fbq(type, name, data);
    } else {
        window.fbq(type, name);
    }
}

async function simpleEvent(type, name, testCode = null) {
    window.fbq("init", pixelId);
    if(test){
        window.fbq(type, name, {test_event_code: testCode});
    } else {
        window.fbq(type, name,);
    }
}

// fbq('track', 'ViewContent', {
//     content_name: 'Content Title',
//     content_category: 'Learning',
//     content_ids: ['1234'],
//     content_type: 'product',
//     value: 0.50,
//     currency: 'MXN'
//    }); {test_event_code: 'test123'}

import { data } from "jquery";
import React, { useEffect, useState } from "react";

import "./registerServices.scss";

export function FormAdditional(props) {
  //debugger
  let {
    website,
    facebook,
    instagram,
    linkedin,
    twitter,
    tiktok,
  } = props.data;



  const [datos, setDatos] = useState({
    sitioweb: null,
    sitiowebId: null,
    facebook: null,
    facebookId: null,
    instagram: null,
    instagramId: null,
    linkedin: null,
    linkedinId: null,
    twitter: null,
    twitterId: null,
    tiktok: null,
    tiktokId: null
  })
  const [facebookEjemplo, setFacebookEjemplo] = useState(false);
  const [instagramEjemplo, setInstagramEjemplo] = useState(false);
  const [linkedinEjemplo, setLinkedinEjemplo] = useState(false);
  const [twitterEjemplo, setTwitterEjemplo] = useState(false);
  const [tikTokEjemplo, setTikTokEjemplo] = useState(false);

  const handleInputChange = (event) => {
    let nombre=event.target.name;
    nombre+="Id";
    setDatos({
      ...datos,
      [nombre]: event.target.valId
    })

    setDatos({
      ...datos,
      [event.target.name]: event.target.value
    })

    props.Datepersonal({
      ...datos,
      [nombre]: event.target.valId
    }, "additional");
    props.Datepersonal({
      ...datos,
      [event.target.name]: event.target.value
    }, "additional");

  }

  useEffect(() => {
    let Data = {};
    if (props.register.personal.hasOwnProperty("facebook")) {
      let reg = props.register.personal;
      Data = {
        sitioweb: reg.website,
        facebook: reg.facebook,
        instagram: reg.instagram,
        linkedin: reg.linkedin,
        twitter: reg.twitter,
        tiktok: reg.tiktok,
      };
      setDatos(Data);
    }


    if (props.data?.directoryContacts) {
      let directoryContacts = props.data.directoryContacts;
      directoryContacts.forEach(element => {
        switch (element.contactTypeId) {
          case 3: Data.sitioweb = element.contactValue;
            Data.sitiowebId = element.directoryContactId;
            break;
          case 4: Data.linkedin = element.contactValue;
            Data.linkedinId = element.directoryContactId; 
            break;
          case 5: Data.facebook = element.contactValue;
            Data.facebookId = element.directoryContactId; 
            break;
          case 6: Data.twitter = element.contactValue;
            Data.twitterId = element.directoryContactId; 
            break;
          case 7: Data.instagram = element.contactValue;
            Data.instagramId = element.directoryContactId; 
            break;
          case 8: Data.tiktok = element.contactValue;
           Data.tiktokId = element.directoryContactId; 
           break;
          default: break;
        }
      });
      setDatos(Data);
    }
  }, [props.data]);




  function enviadatos() {
    props.Datepersonal(datos, "additional");
    props.toggleView();
  }

  return (
    <>
      <div className="bar_step_reg">
        <div className="box_item_step step_active">
          <span className="item_step">1</span>
        </div>
        <div className="box_item_step step_active">
          <span className="item_step">2</span>
        </div>
        <div className="box_item_step">
          <span className="item_step">3</span>
        </div>

      </div>
      <div className="content_shadows_form">
        <div className="text-right mb-2">
          <span className="goback trans " onClick={props.toBack}>
            <ion-icon name="chevron-back"></ion-icon>
            <span>Volver</span>
          </span>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h6 className="text fnt_medium mb-3">Información adicional</h6>
            <p className="drawer_reg_sex">
              En redes solo agregar el usuario
            </p>
          </div>
          <div className="col-md-12">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  id="inputGroup-sizing-default"
                >
                  Sitio web
                </span>
              </div>
              <input
                type="url"
                defaultValue={datos.sitioweb}
                className="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                valId={datos.sitiowebId}
                name="sitioweb" onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  id="inputGroup-sizing-default"

                >
                  Facebook
                </span>
              </div>
              <input
                type="url"
                defaultValue={datos.facebook}
                className="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                valId={datos.facebookId}
                name="facebook" onChange={handleInputChange}
                onFocus={()=>setFacebookEjemplo(true)}
                onBlur={()=>setFacebookEjemplo(false)}
              />
            </div>
            {
              facebookEjemplo &&
              <p className="drawer_reg_sex">
                  {"https://www.facebook.com/"}
                  <b>{datos.facebook}</b>
              </p>
            }
          </div>
          <div className="col-md-12">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  id="inputGroup-sizing-default"
                >
                  Instagram
                </span>
              </div>
              <input
                type="url"
                defaultValue={datos.instagram}
                className="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                valId={datos.instagramId}
                name="instagram" onChange={handleInputChange}
                onFocus={()=>setInstagramEjemplo(true)}
                onBlur={()=>setInstagramEjemplo(false)}
              />
            </div>
            {
              instagramEjemplo &&
              <p className="drawer_reg_sex">
                  {"https://www.instagram.com/"}
                  <b>{datos.instagram}</b>
              </p>
            }
          </div>
          <div className="col-md-12">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  id="inputGroup-sizing-default"
                >
                  Linkedin
                </span>
              </div>
              <input
                type="url"
                defaultValue={datos.linkedin || ""}
                className="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                valId={datos.linkedinId}
                name="linkedin" onChange={handleInputChange}
                onFocus={()=>setLinkedinEjemplo(true)}
                onBlur={()=>setLinkedinEjemplo(false)}
              />
            </div>
            {
              linkedinEjemplo &&
              <p className="drawer_reg_sex">
                  {"https://www.linkedin.com/in/"}
                  <b>{datos.linkedin}</b>
              </p>
            }
          </div>
          <div className="col-md-12">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  id="inputGroup-sizing-default"
                >
                  Twitter
                </span>
              </div>
              <input
                type="url"
                defaultValue={datos.twitter}
                className="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                valId={datos.twitterId}
                name="twitter" onChange={handleInputChange}
                onFocus={()=>setTwitterEjemplo(true)}
                onBlur={()=>setTwitterEjemplo(false)}
              />
            </div>
            {
              twitterEjemplo &&
              <p className="drawer_reg_sex">
                  {"https://twitter.com/"}
                  <b>{datos.twitter}</b>
              </p>
            }
          </div>
          <div className="col-md-12">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-default" >
                  TikTok
                </span>
              </div>
              <input
                type="url"
                defaultValue={datos.tiktok}
                className="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                valId={datos.tiktokId}
                name="tiktok" onChange={handleInputChange}
                onFocus={()=>setTikTokEjemplo(true)}
                onBlur={()=>setTikTokEjemplo(false)}
              />
            </div>
            {
              tikTokEjemplo &&
              <p className="drawer_reg_sex">
                  {"https://www.tiktok.com/@"}
                  <b>{datos.tiktok}</b>
              </p>
            }
          </div>
          <hr />
          <div className="col-md-12 text-center">
            <hr />
            <button
              type="button"
              className="btn secundary"
              onClick={enviadatos}
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { backendService } from "./../../services/backend.service";
import { UserActivity } from "../../components/userActivity/userActivity";
import { genderIdentityService } from "../../services/genderIdentity.service";
import { userService } from "../../services/user.service";
import { Pending } from "./pending";
import { useForm } from "react-hook-form";

import "./profile.scss";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";

export function Profile() {
  const trigger = undefined;
  const [disabled, setDisabled] = useState(true);
  const [statusGender, setStatusGender] = useState(false);
  const [profile, setProfileInfo] = useState();
  const [changeImage, setChangeImage] = useState(false);
  const [selectedIdentity, setSelectedIdentity] = useState(0);
  const [nickname, setNickname] = useState("");
  const [backupIdentity, setBackupIdentity] = useState({ id: 0, flag: "" });
  const [genderIdentityCatalog, setGenderIdentityCatalog] = useState([]);
  const [selectedGenderIdentityFlag, setSelectedGenderIdentityFlag] = useState(
    {}
  );
  const [identityFlag, setIdentityFlag] = useState("");
  const [btnImage, setBtnImage] = useState("fa fa-edit");
  const [originValues, setOriginValues] = useState({
    additionalEmail: "",
    nickname: "",
  });
  const [inputClass, setInputClass] = useState(
    "form-control-plaintext text-primary"
  );
  const [placeHolderText, setPlaceHolderText] = useState("");
  const { register, handleSubmit } = useForm();
  const companyId = useSelector((state) => state.userSettings.companyId);

  useEffect(() => {
    getProfileInfo();
    setDisabled(true);
    handleDisabled();
  }, [trigger]);

  const getProfileInfo = () => {
    genderIdentityService.getAll().then((genderData) => {
      setGenderIdentityCatalog(genderData);
    });
    backendService.userProfile().then((data) => {
      setOriginValues({
        additionalEmail: data.additionalEmail ?? "",
        nickname: data.nickname ? data.nickname : "",
      });
      setProfileInfo(data);
      setIdentityFlag(data.genderIdentityFlag);
      setSelectedIdentity(data.genderIdentityId);
      setNickname(data.nickname);
    });
  };

  const handleChange = (event) => {
    setChangeImage(event.target.files.length > 0);
    if (event.target.files.length > 0)
      setProfileInfo({
        ...profile,
        urlProfileImage: URL.createObjectURL(event.target.files[0]),
      });
  };

  const onSubmit = (data, e) => {
    if (selectedIdentity) data.GenderIdentityId = selectedIdentity;
    data.GenderIdentityFlag = identityFlag;

    data.Mobile = data.Mobile == undefined ? null : data.Mobile;
    data.nickname = nickname;
    data.Preferences = data.Preferences == undefined ? null : data.Preferences;

    userService.editProfile(data, companyId).then((result) => {
      if (result) {
        setChangeImage(false);
        setOriginValues({
          additionalEmail: profile.additionalEmail ?? "",
          nickname: profile.nickname ? profile.nickname : "",
        });
      } else {
        setProfileInfo({
          ...profile,
          additionalEmail: originValues.additionalEmail,
          nickname: originValues.nickname,
        });
      }

      setDisabled(true);
      setBtnImage("fa fa-edit");
      setInputClass("form-control-plaintext text-primary");
      setPlaceHolderText("");
    });
  };
  const onGenderChange = (event) => {
    if (selectedIdentity)
      setBackupIdentity({ id: selectedIdentity, flag: identityFlag });

    setSelectedIdentity(event.target.value);
    const selectedDataGender = genderIdentityCatalog.find(
      (item) => item.genderIdentityId == event.target.value
    );
    setIdentityFlag(selectedDataGender.identityFlagName);

    if (profile.genderIdentityId == parseInt(event.target.value)) {
      setStatusGender(false);
    } else {
      setStatusGender(true);
    }
  };

  const onNickChange = (event) => {
    setNickname(event.target.value);
    if (profile.nickname == event.target.value) {
      setStatusGender(false);
    } else {
      setStatusGender(true);
    }
  };

  const handleDisabled = () => {
    setDisabled(!disabled);
    if (disabled) {
      setBtnImage("fas fa-times");
      setInputClass("form-control");
      setPlaceHolderText("Ingrese un sobre nombre");
    } else {
      setBtnImage("fa fa-edit");
      setInputClass("form-control-plaintext text-primary");
      setPlaceHolderText("");
      setSelectedIdentity(backupIdentity.id);
      setIdentityFlag(backupIdentity.flag);
      setBackupIdentity({ id: 0, flag: "" });
      setNickname(originValues.originValues);
      setProfileInfo({
        ...profile,
        additionalEmail: originValues.additionalEmail,
        nickname: originValues.nickname,
        // genderIdentityId: selectedIdentity
      });
    }
  };

  const [asideShow, setAsideshow] = useState("");
  const activeAside = () => {
    setAsideshow(asideShow === "" ? " is-show" : "");
  };

  const ProfileImage = register("ProfileImage");

  return (
    <section className="wrapper_profile padd">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt">
          <h2 className="mb-0 text fnt_medium">Mi Perfil</h2>
        </div>
      </div>

      <div className="content_profile">
        <div className="box_main_info_profile">
          <span className="btn_ellipsis" onClick={activeAside}>
            <i className="fas fa-ellipsis-v"></i>
          </span>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="box_user_info">
              <div className="main_info">
                <div className="box_one">
                  <div className="wrapper__bg_flags">
                    {identityFlag ? (
                      <div className={`bgflag ${identityFlag} `}></div>
                    ) : (
                      ""
                    )}
                    <div className="contain_avatar">
                      <div className="box_avatar">
                        <img
                          src={profile && profile.urlProfileImage}
                          alt="NameUser"
                        />
                      </div>
                    </div>
                    <p className="note drawer_reg_sex">¡Sube tu foto!</p>
                  </div>

                  <div>
                    <label
                      htmlFor="file-upload"
                      className="custom-file-upload icons"
                    >
                      <ion-icon name="camera-outline"></ion-icon>
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      name="ProfileImage"
                      accept="image/*"
                      {...register("ProfileImage")}
                      onChange={(e) => {
                        ProfileImage.onChange(e);
                        handleChange(e);
                      }}
                    />
                    {changeImage && (
                      <button type="submit" className="icons ico_save">
                        <i className="fas fa-check"></i>
                      </button>
                    )}
                  </div>
                  <div className="profile_info">
                    <h4 className="text fnt_medium mb-0">
                      {profile && profile.middleName}{" "}
                      {profile && profile.lastName}{" "}
                      {profile && profile.firstName}
                    </h4>
                    <h6 className="">{profile && profile.nickname}</h6>
                    <h6 className="">{profile && profile.jobRole}</h6>
                  </div>
                </div>
              </div>

              <div className="second_info">
                {/* <div>
                  <h6 className="text fnt_bold">
                    <small>NO. USUARIO</small>{" "}
                  </h6>
                  <h6 className="text fnt_bold c_primary">
                    {profile && profile.employeeNo}
                  </h6>
                </div> */}
                <div>
                  <h6 className="text fnt_bold">
                    <small>FECHA DE NACIMIENTO</small>{" "}
                  </h6>
                  <h6 className="">
                    <Moment locale="es" format="DD [de] MMMM  YYYY">
                      {profile && profile.birthday}
                    </Moment>
                  </h6>
                </div>
                <div>
                  <h6 className="text fnt_bold">
                    <small>ANTIGÜEDAD</small>{" "}
                  </h6>
                  <h6 className="">{profile && profile.seniority}</h6>
                </div>
                <div>
                  <h6 className="text fnt_bold">
                    <small>BANDERA</small>{" "}
                  </h6>
                  <h6 className="">{profile && profile.genderName}</h6>
                </div>
                {/* <div>
                  <h6 className="text fnt_bold">
                    <small>SUCURSAL</small>{" "}
                  </h6>
                  <h6 className="">{profile && profile.branchOfficeName}</h6>
                </div> */}
              </div>
            </div>
            <div className="box_details_account card shadows">
              <h5 className="text fnt_medium ">
                <i className="far fa-user-circle"></i> Detalles de la cuenta
                {/* <a className="btn-bitbucket" onClick={handleDisabled}>
                  <i className={btnImage}></i>
                </a> */}
              </h5>
              <hr className="mt-0" />
              <div className="drawer_acount_details">
                {/* <p className="drawer_reg_sex">
                  Elige la bandera con la que te identifiques
                </p> */}
                <div className="col-md-6">
                  {/* <div className="mb-3 acount_info_details space">
                    <div className="text fnt_medium">Banderas de orgullo</div>
                    <select
                      className="form-control form-control-sm"
                      disabled={disabled ? "disabled" : ""}
                      value={selectedIdentity}
                      onChange={onGenderChange}
                    >
                      <option value={0} defaultValue>
                        Selecciona tu bandera
                      </option>
                      {genderIdentityCatalog &&
                        genderIdentityCatalog.map((gender) => (
                          <option
                            key={gender.genderIdentityId}
                            value={gender.genderIdentityId}
                          >
                            {gender.genderName}
                          </option>
                        ))}
                    </select>
                  </div> */}
                  <div className="mb-3 acount_info_details space">
                    <div className="text fnt_medium">Nickname:</div>
                    <input
                      type="text"
                      value={nickname}
                      className="form-control form-control-sm"
                      onChange={onNickChange}
                    />
                  </div>
                </div>
              </div>

              {statusGender ? (
                <div>
                  <hr />
                  <div className="mb-2 mt-2 text-center">
                    <button type="submit" className="btn primary minimum ">
                      Guardar
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </form>
          <UserActivity />
        </div>
        <Pending
          profile={profile}
          asideShow={asideShow}
          allClose={activeAside}
        />
      </div>
    </section>
  );
}

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import swal from "sweetalert"
import { ecommerceService } from '../../../services/ecommerce.service'
import { cartHelper } from '../../../helpers/cart.helper'
import { jqueryHelper } from '../../../helpers/jquery.helper'

const ItemPayMethod = ({ onCloseClick, method }) => {
    // const dispatch = useDispatch();
    const history = useHistory();
    const [onlyOneClick, setOnlyOneClick] = useState(false)


    const payOrder = () => {
        if (!onlyOneClick) {
            setOnlyOneClick(true);

            const model = {
                Products: cartHelper.getItems(),
                PaymentMethodId: method.paymentMethodId
            };

            if (model.Products.length > 0) {
                //dispatch(toggleBlocking());
                ecommerceService.payOrder(model)
                    .then(function (result) {
                        if (result != undefined) {
                            setOnlyOneClick(false);
                            //dispatch(toggleBlocking());
                            if (onCloseClick != undefined) onCloseClick();
                            window.location.href = result
                        } else {
                            setOnlyOneClick(false);
                            //dispatch(toggleBlocking());
                            swal({ text: "Ocurrió un error al abrir método de pago", icon: "error" })
                        }
                    });
            } else {
                swal({ text: "No hay ningún producto en el carrito de compras", icon: "error" })
                setOnlyOneClick(false);
            }
        }
    }

    return (
        <button
            className="item_paymethod trans"
            onClick={payOrder}
        >
            <img src={method.urlLogo} alt="Paga con Red Pay" />
        </button>
    )
}

export default ItemPayMethod
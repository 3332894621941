import React, { useState } from 'react';
import ItemPurchaseDetails from '../itemPurchaseDetails/itemPurchaseDetails'
import './itemPurchase.scss'
import { useSelector } from 'react-redux';

const ItemPurchase = (props) => {
    const countrySetting = useSelector(state => state.countrySetting);

    const [activeAccordion, setStateAccordion] = useState('')
    const [activeChevron, setStateChevron] = useState('')

    function AsidePurchaseBox() {
        setStateAccordion(activeAccordion === '' ? 'show' : '')
        setStateChevron(activeChevron === '' ? 'open' : '')
    }

    const items = props.Items;
    return (
        <div className="accordion item_purchase" id="accordionExample">
            <span className="btn_delete_group trans" onClick={() => props.deleteItem(props.ProviderId, props.ProviderName)}><i className="far fa-trash-alt"></i> <span>Eliminar</span></span>
            <div className="card">
                <div className="card-header" id="headingOne" >
                    <div className="box_logo_shop">
                        <span className="bg_logo_shop">
                            <img src={props.Logo} alt="Nombre tienda" />
                        </span>
                    </div>
                    <div className="w-100">
                        <h5 className="text fnt_medium m-0">{props.ProviderName}</h5>
                        <p className="to_shop"><a href={`/provider/${props.ProviderId}`}>Ir a la tienda <i className="fas fa-chevron-right trans"></i></a></p>
                    </div>
                    <div className="lastdata">
                        <h5 className="text fnt_medium">{countrySetting.currency}{(props.Total).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</h5>
                        <span className={`ico_chevron ${activeChevron}`} onClick={AsidePurchaseBox}>
                            <i className='fas fa-chevron-down'></i>
                        </span>
                    </div>
                </div>
                <div id="collapseOne" className={`collapse ${activeAccordion}`} aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="card-body">
                        {
                            items.map(itemDetail => {
                                return <ItemPurchaseDetails {...itemDetail} deleteItemDetail={props.deleteItemDetail} onChangeQuantity={props.onChangeQuantity} />
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemPurchase
import React, { useEffect } from "react";
import "./oxxoPay.scss";
import { cartHelper } from '../../../helpers/cart.helper';
import { Link } from 'react-router-dom';


export function OxxoPay() {
    useEffect(() => {
        cartHelper.clean();
    });

    return (
        <section className="oxxopay-section">
            <h1>Felicidades</h1>
            <p className="oxxopay-p">Estas muy cerca de tener tus folios.</p>
            <p>Te hemos enviado un email, con las instrucciones para que realices tu pago en Oxxo Pay.</p>
            <img src="./imgs/paymentMethods/oxxo-pay.png" alt="Oxxo Pay" />
            <i className="fas fa-check-circle fa-4x"></i>
            <p><Link to="/discount">Regresar al inicio</Link></p>
        </section>
    );
}
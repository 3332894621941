import React, { useState } from "react";
import BgConfen from "../../assets/imgs/banner_confer.jpg";
import _default from "react-bootstrap/esm/CardGroup";
import moment from "moment";
import { metaPixelService } from "../../services/metaPixel.service";

export function BoxListAudience(props) {
  return (
    <div className=" content_card_audience">
      <h4 className="text-muted text fnt_medium mb-3">
        {props.Title}
      </h4>
      <div className="box_list_audience">
        {/* <p className="mt-3">Actualmente tu organización no tiene contratado este módulo. Si requieres activarlo pídele a tu administrador de recursos humanos que se ponga en contacto con nosotros. </p> 
          title={item.data.title}
              conferenceDate={moment(item.data.conferenceDate).format('LLLL')}
              urlImage={item.files[0].path}
              conferenceUrl={item.conferenceUrl}
        */}
        <div className="row">
          {props.Items && props.Items
            .sort((a, b) => a.data.conferenceDate > b.data.conferenceDate ? 1 : -1)
            .map(item => (

            <div className="col-sm-6 col-lg-4 mb-4">
                <a
                    className="item_audience shadows card"
                    href={item.data.conferenceUrl}
                    // onClick={() => metaPixelService.trackEvent('track','ViewContent',{content_name: item.data.title,link: item.data.conferenceUrl})}
                    target={_default}
                >
                <div className="box_content_img">
                    {
                      moment(item.data.conferenceDate).isBefore(new Date(), 'minutes') &&
                        <span class="dots_notif"><svg height="12" width="12" class="blinking">
                        <circle cx="6" cy="6" r="4" fill="red" />
                        .  
                        </svg> En Vivo</span>
                    }
                    <img src={item.files.length > 0 && item.files[0].path} alt={item.data.title} />
                </div>
                <h5 className="mb-0 text fnt_bold ">{item.data.title}</h5>
                <p className="paragraph myp">{moment(item.data.conferenceDate).format('LLLL')}</p>
                <p className="paragraph text-muted m-0"><small>Conferencias</small></p>
              </a>
            </div>

          ))}
        </div>
      </div>
    </div>
  );
}

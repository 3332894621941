import React, { Fragment, useEffect, useState } from "react";
import { courseViewService } from "../../services/courseView.service";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import ReactPlayer from "react-player";

export function BoxListCourse({ dataGrid, setCompleteCourse }) {
  const calendarStrings = useSelector((state) => state.calendarStrings);
  const [course, setCourse] = useState({ type: 0, value: "", fileType: "" });
  const imageExt = ['jpg', 'jpeg', 'png'];

  const handleOnFiles = (file) => {
    courseViewService
      .getFileById(file.entityId, file.entityTypeId, file.sequence)
      .then((result) => {
        if (result) {
          const fileType = result.fileName.split(".")[1];
          if (fileType == "pdf")
            toggleCourse(
              1,
              `data:application/${fileType};base64,${result.file}`,
              fileType
            );
          else if (fileType == "mp4") handleVideoUrl(file.path, fileType);
          else downloadFile(file.fileName, result.file);
        }
      });
  };

  const handleComplete = (event, course) => {
    setCompleteCourse(course);
    event.preventDefault();
  };

  const handleUrl = (event, course, type) => {
    setCompleteCourse(course);
    toggleCourse(type, course.urlMedia);
    event.preventDefault();
  };

  const toggleCourse = (type, value, fileType) => {
    setCourse({ type, value, fileType });
    toggle();
  };

  const downloadFile = (fileName, fileb64) => {
    const linkSource = `data:application/octet-stream;base64,${fileb64}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleVideoUrl = (link, fileType) => {
    if (link.length > 0) {
      toggleCourse(1, link, fileType);
    }
  };

  const imageBox = (itemFile) => {
    var image = imageLink("document-outline");
    var re = /(?:\.([^.]+))?$/;

    var ext = re.exec(itemFile.fileName)[1];
    if (imageExt.includes(ext)) {
      var imageBack = { backgroundImage: `url(${itemFile.path})` }
      image = <div className="boxImage" style={imageBack} />
    }

    return image;
  }

  const imageBoxLink = (itemFile) => {
    var image = imageLink("unlink-outline");
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(itemFile.fileName)[1];
    if (imageExt.includes(ext)) {
      var imageBack = { backgroundImage: `url(${itemFile.path})` }
      image = <div className="boxImage" style={imageBack} />
    }
    return image;
  }

  const imageLink = (icon) => {
    var image = (
      <div className="boxImage icon">
        <p>
          <ion-icon name={icon}></ion-icon>
        </p>
      </div>
    );
    return image;
  };

  const imageYouTube = (frame) => {
    var image = imageLink("play-circle-outline");
    var url = "";
    var youtubeid = "";
    var first = frame.split('src="');
    if (first.length > 1) {
      var second = first[1].split('"');
      if (second.length > 0) {
        url = second[0];
        var third = url.split("embed/");
        if (third.length > 0) {
          youtubeid = third[1];
          var videoThumb = "https://img.youtube.com/vi/" + youtubeid + "/0.jpg";
          var imageBack = { backgroundImage: `url(${videoThumb})` };
          image = <div className="boxImage" style={imageBack} />;
        }
      }
    }
    return image;
  };
  return (
    <Fragment>
      <h4 className="text-muted text fnt_medium">Eventos disponibles</h4>
      <div className="box_list_audience row">
        {dataGrid.map((item) => (
          <article className=" col-12 col-sm-9 col-md-6  col-lg-4 mb-4">
            <div className="card shadows">
              <div className="box_img_stories left_image">
                {
                  {
                    1: imageBox({ fileName: item.fileName, path: item.path }),
                    2: (
                      <a
                        className="item_audience"
                        key={item.courseId}
                        href={item.urlMedia}
                        target="_blank"
                      >
                        {imageBoxLink({ fileName: item.fileName, path: item.path })}
                      </a>
                    ),
                    3: (
                      <a
                        className="item_audience"
                        key={item.courseId}
                        href={item.urlMedia}
                        onClick={(event) =>
                          handleUrl(
                            event,
                            item,
                            item.courseMediaTypeId
                          )
                        }
                      >
                        {imageYouTube(item.urlMedia)}
                      </a>
                    ),
                  }[item.courseMediaTypeId]
                }
              </div>
              <div className="content_info_course">
                <h5 className="card-title mt-2 mb-0 text fnt_medium">
                  {item.name}
                </h5>
                <p className="p_paragraph">{item.description} </p>

                {/* <Moment calendar={calendarStrings}>
                        {item.data.endDate}
                      </Moment> <br /> */}
                {item.courseMediaTypeId == 1 &&
                    <a className="" onClick={() => handleOnFiles({ fileName: item.fileName, path: item.path })}>
                      <p className="text-right mb-0 c_primary cursor">
                        {item.fileName}
                      </p>
                    </a>}
                {item.courseMediaTypeId == 2 && (
                  <a
                    className="item_audience"
                    key={item.courseId}
                    href={item.urlMedia}
                    target="_blank"
                  >
                    <p className="text-right mb-0 c_primary cursor">Abrir</p>
                  </a>
                )}
                {item.courseMediaTypeId == 3 && (
                  <a
                    className="item_audience"
                    key={item.courseId}
                    href={item.urlMedia}
                    onClick={(event) =>
                      handleUrl(event, item, item.courseMediaTypeId)
                    }
                  >
                    <div className="text-right mb-0 c_primary cursor" style={{ fontSize: '22px' }}>
                      <ion-icon name="play-circle-outline"></ion-icon>
                    </div>
                  </a>
                )}
              </div>
            </div>
          </article>
        ))}
      </div>

      <Modal isOpen={modal} toggle={toggle} className="wrapper_modal_cap">
        {
          {
            1: (
              <div>
                <div className="toolbar_opt_modal">
                  <span className="btns_modal text fnt_bold" c>
                    <i className="far fa-window-close"></i> <span>CERRAR</span>
                  </span>
                </div>

                {
                  {
                    pdf: <iframe src={course.value}></iframe>,
                    mp4: (
                      <ReactPlayer
                        url={course.value}
                        width="100%"
                        height="100%"
                        controls={true}
                      />
                    ),
                  }[course.fileType]
                }
              </div>
            ),
            3: (
              <div>
                <div className="toolbar_opt_modal">
                  <span className="btns_modal text fnt_bold" onClick={toggle}>
                    <i className="far fa-window-close"></i> <span>CERRAR</span>
                  </span>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: course.value }}
                  style={{ display: "contents" }}
                />
              </div>
            ),
          }[course.type]
        }
      </Modal>
    </Fragment>
  );
}

import fetchIntercept from "fetch-intercept";
import { setBlocking } from "./hooks/blockingHook";
import { getToken, validateToken } from "./hooks/authHook";

export const registerInterceptor = fetchIntercept.register({
  request: function (url, config) {
    // Modify the url or config here

    if (config) {
      const token = getToken();
      if (!config.headers) config.headers = {};
      config.headers.Authorization = "Bearer " + token.access;
      setBlocking(!config.headers.NoBlocking);
      if (!config.headers.SkipValidationToken) validateToken(token);
    }
    return [url, config];
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call
    setBlocking(false);
    return Promise.reject(error);
  },

  response: function (response) {
    // Modify the reponse object
    setBlocking(false);

    if (response.status === 401) {
      const token = getToken();
      validateToken(token);
    }

    return response;
  },

  responseError: function (error) {
    // Handle an fetch error
    setBlocking(false);
    return Promise.reject(error);
  },
});

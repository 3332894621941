import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FormPersonal } from "./formPersonal";
import { FormAdditional } from "./formAdditional";
import { FormCheckoutPay } from "./formCheckoutPay";
import { MessageFinish } from "./messageFinish";
import { directoryInfoService } from "../../services/directoryService.service";
import { useParams } from "react-router-dom";
import "./registerServices.scss";

export function RegisterServices(props) {
  let { id } = useParams();
  const [pubToEdit, setPublicationToEdit] = useState({});
  const [isEdit, setIdEdit] = useState(false);
  const [loadPicture, setLoadPicture] = useState(false);
  const [file, setFile] = useState(null);

  const [viewForm, setViewForm] = useState("1");
  const toggleView = (sec) => {
    if (viewForm == 2 && sec == 3) subirForm();
    if (viewForm !== sec) setViewForm(sec);
  };
  const [register, setRegister] = useState({
    personal: {},
    additional: {},
    pay: {},
  });

  useEffect(() => {
    if (id !== "" && id !== undefined) {
      setLoadPicture(true);
      setIdEdit(true);
      directoryInfoService.getById(id).then((data) => {
        setPublicationToEdit(data);
      });
    }
  }, []);

  function Datepersonal(event, name) {
    const prevRegister = register;
    prevRegister[name] = event;
    setRegister(prevRegister);
  }

  function subirForm() {
    let directoryContacts = [
      {
        contactTypeId: 1,
        contactValue: register.personal.contacto1,
        directoryContactId: register.personal?.contacto1DirectoryContactId,
      },
      {
        contactTypeId: 2,
        contactValue: register.personal.correo,
        directoryContactId: register.personal?.contacto2DirectoryContactId,
      },
    ];

    if (register.additional?.sitioweb) {
      directoryContacts.push({
        contactTypeId: 3,
        contactValue: register.additional?.sitioweb,
        directoryContactId: register.additional?.sitiowebId,
      });
    }
    if (register.additional?.linkedin) {
      directoryContacts.push({
        contactTypeId: 4,
        contactValue: register.additional?.linkedin,
        directoryContactId: register.additional?.linkedinId,
      });
    }
    if (register.additional?.facebook) {
      directoryContacts.push({
        contactTypeId: 5,
        contactValue: register.additional?.facebook,
        directoryContactId: register.additional?.facebookId,
      });
    }
    if (register.additional?.twitter) {
      directoryContacts.push({
        contactTypeId: 6,
        contactValue: register.additional?.twitter,
        directoryContactId: register.additional?.twitterId,
      });
    }
    if (register.additional?.instagram) {
      directoryContacts.push({
        contactTypeId: 7,
        contactValue: register.additional?.instagram,
        directoryContactId: register.additional?.instagramId,
      });
    }
    if (register.additional?.tiktok) {
      directoryContacts.push({
        contactTypeId: 8,
        contactValue: register.additional?.tiktok,
        directoryContactId: register.additional?.tiktokId,
      });
    }

    const formData = new FormData();
    formData.append("CompanyId", process.env.REACT_APP_COMPANY);
    formData.append("contactImage", file);
    formData.append("title", "lic.");
    formData.append("fullName", register.personal.nombre);
    formData.append("location", register.personal.location);
    formData.append("subCategoryServiceId", register.personal.subcategoria);
    formData.append("createUser", localStorage.getItem("userId"));

    directoryContacts.forEach((item, index) => {
      formData.append(
        `directoryContacts[${index}].contactTypeId`,
        item.contactTypeId
      );
      formData.append(
        `directoryContacts[${index}].contactValue`,
        item.contactValue
      );
      if (item.directoryContactId) {
        formData.append(
          `directoryContacts[${index}].directoryContactId`,
          item.directoryContactId
        );
      }
    });

    if (isEdit) {
      formData.append("directoryInfoId", pubToEdit.directoryInfoId);
      directoryInfoService.updateDirectory(formData).then((result) => {
        if (result?.data) setPublicationToEdit(result.data);
      });
    } else
      directoryInfoService.insertDirectory(formData).then((result) => {
        if (result?.data) setPublicationToEdit(result.data);
        const newResult = register;

        const lstContact = result.data.directoryContacts.filter(
          (element) => element.contactTypeId === 1
        );
        if (lstContact.length > 0) {
          newResult.personal.contacto1DirectoryContactId =
            lstContact[0].directoryContactId;
        }

        const lstContact2 = result.data.directoryContacts.filter(
          (element) => element.contactTypeId === 2
        );
        if (lstContact2.length > 0) {
          newResult.personal.contacto2DirectoryContactId =
            lstContact2[0].directoryContactId;
        }

        setRegister(newResult);

        setIdEdit(true);
      });
  }
  return (
    <div className="wrapper_reg_services">
      <div className="content_services">
        <Link to="/directory" className="goback trans">
          <span className="ml-1">Ir a Directorio</span>
        </Link>
        <h2 className="text fnt_medium text-center mt-2">
          Registro de servicios
        </h2>
        {/* <p className="paragraph text-center">
          Llena el siguiente formulario con tus datos para poder continuar con
          tu alta de servicios.
        </p> */}
        <p className="paragraph text-center">
          Ser proveedor tiene un costo de $99 mensuales, se realiza un cargo
          recurrente al darte de alta. Tu servicio debe ser aprobado por el{" "}
          <strong>Administrador</strong>. <br /> ¡Pronto será publicada!
        </p>
        {/* <div className="box_animate_text">
          <p className="drawer_reg_sex">
            Te damos los primeros 60 días por un costo de $10 pesos
          </p>
        </div> */}
        <div className="wrapper_form">
          <div className="form_info_service">
            {
              {
                1: (
                  <FormPersonal
                    data={pubToEdit}
                    register={register}
                    viewForm={viewForm}
                    loadPicture={loadPicture}
                    setLoadPicture={setLoadPicture}
                    setFile={setFile}
                    Datepersonal={Datepersonal}
                    toggleView={() => toggleView("2")} //sig
                  />
                ),
                2: (
                  <FormAdditional
                    viewForm={viewForm}
                    data={pubToEdit}
                    register={register}
                    Datepersonal={Datepersonal}
                    toggleView={() => toggleView("3")}
                    toBack={() => toggleView("1")}
                  />
                ),
                3: (
                  <FormCheckoutPay
                    viewForm={viewForm}
                    data={pubToEdit}
                    Datepersonal={Datepersonal}
                    toggleView={() => toggleView("4")}
                    toBack={() => toggleView("2")}
                  />
                ),
                4: <MessageFinish />,
              }[viewForm]
            }
          </div>
        </div>
      </div>
    </div>
  );
}

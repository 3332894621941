import React from "react";

export function Legals() {
  return (
    <div className="nav_legals">
      <a target="_blank" href="/avisoprivacidad.pdf">
        Aviso de Privacidad
      </a>
      {/* <br />
      <a target="_blank" href="/terminosycondiciones.pdf">
        Términos y Condiciones
      </a> */}
    </div>
  );
}

import React, { useRef, useState } from 'react';
import {
    Col, Form, FormGroup, Label, Input, Row, Card, CardImg,
    CardTitle, Button, CardColumns, Table
} from "reactstrap";
import useInputSingleForm from '../../components/CustonHooks/inputFormHook';
import { GeneralUpload } from '../../components/Helpers/generalUpload';
import { storageService } from '../../services/storage.service';
import SearchGalleries from '../../components/companyPost/searchGalleries';
import { companyPostService } from '../../services/companyPost.service';
import { SelectCustom } from '../../components/Helpers/SelectCustom';

export function AdminGallery() {

    let companyInit = { CompanyPostId: 0, Title: '', Subtitle: '', Text: '', DepartmentId: 0, JobRoleId: 0 }
    const [localfiles, setLocalFiles] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [dataUsers, setDataUsers] = useState([]);
    const [userSelec, setuserSelec] = useState([]);
    const [images, setImages] = useState([]);
    const [postType, setPostType] = useState(1);
    const [newImages, setNewImages] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const gridRef = useRef();
    const onSave = () => {
        let model = {
            CompanyPostId: parseInt(inputs.CompanyPostId), Title: inputs.Title, Subtitle: inputs.Subtitle, Text: inputs.Text, PostType: postType, ListUser: userSelec
        }

        if (parseInt(inputs.CompanyPostId) === 0) {
            companyPostService.create(model, localfiles).then((result) => {
                gridRef.current.refreshDataGrid();

            });
        } else {
            companyPostService.update(model, localfiles).then((result) => {
                gridRef.current.refreshDataGrid();
            });
        }
        setuserSelec([]);
        setDataUsers([]);
        setInputs({});
        setShowEdit(false);
        setLocalFiles([]);
        setPostType(1);
        setImages([]);
        setSelectedItem({});
    }
    const { inputs, handleInputChange, handleSubmit, setInputs } = useInputSingleForm(onSave, {});

    const handleOnCancel = () => {
        setInputs({});
        setShowEdit(false);
        setuserSelec([]);
        setDataUsers([]);
        setLocalFiles([]);
        setPostType(1);
        setImages([]);
        setSelectedItem({});
    }

    const handleOnChangeFile = (files) => {
        setLocalFiles(files);
    }

    const handleOnNewGallery = () => {
        setInputs(companyInit);
        setShowEdit(true);
        setuserSelec([]);
        setDataUsers([]);
        setImages([]);
        setLocalFiles([]);
        setPostType(1);
        setSelectedItem({});
    };
    const handleRowSelect = (gridItem) => {
        setShowEdit(true);
        setPostType(gridItem.postType);
        setSelectedItem(gridItem);

        setInputs({ CompanyPostId: gridItem.companyPostId, Title: gridItem.title, Subtitle: gridItem.subtitle, Text: gridItem.text, PostType: gridItem.postType });
        companyPostService.getCompanyPostImagesPathById(gridItem.companyPostId).then((result) => {
            setImages(result.files);
        });
    }

    const handleOnSearch = () => {
        let model = {
            DepartmentId: parseInt(inputs.DepartmentId === '' ? 0 : inputs.DepartmentId), JobRoleId: parseInt(inputs.JobRoleId === '' ? 0 : inputs.JobRoleId)
        }
        companyPostService.getPostUsers(model)
            .then((result) => {
                if (result) {
                    setDataUsers(result);
                }
            });
    }

    const handleSelectUser = (event, data) => {
        if (event.target.checked) {
            setuserSelec([...userSelec, { UserProfileId: data.userProfileId, EmployeeNo: data.employeeNo }]);
        } else {

            let result = userSelec.filter((item) => item.UserProfileId !== data.userProfileId);
            setuserSelec(result);
        }
        event.stopPropagation();
    };

    const handleDeleteImageGallery = (entityId, entityTypeId, sequence) => {
        storageService.deleteStorage({ EntityId: entityId, EntityTypeId: entityTypeId, Sequence: sequence })
        setImages(
            images.filter(function (image) {
                return image.sequence !== sequence
            })
        );
    };
    const handleChangeImageGallery = (entityId, entityTypeId, sequence) => {
        var data = newImages.filter(image => image.sequence === sequence);
        if (data && data.length > 0) {
            storageService.updateStorage({ EntityId: entityId, EntityTypeId: entityTypeId, Sequence: sequence }, data[0].file);
            companyPostService.getCompanyPostImagesPathById(entityId).then((result) => {
                setImages(result.files);
            });
        }
    };
    const handleImageGallerySelected = (event, entityId, entityTypeId, sequence) => {
        let imageList = [];
        if (event.target.files !== null && event.target.files.length > 0) {
            var targetfiles = event.target.files;
            imageList = [...newImages.filter(image => image.sequence !== sequence), { sequence: sequence, file: targetfiles[0] }];
            setNewImages(imageList);
        }
    }

    return (
        <Row>
            <Col xs="2">
                <table>
                    <thead>
                        <tr>
                            <th>Galer�a</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>
                                <SearchGalleries handleRowSelect={handleRowSelect} ref={gridRef} postType={1} />
                            </th>
                        </tr>
                    </tbody>
                </table>
            </Col>
            <Col xs="8">
                <div>
                    <Label for="Title">{selectedItem.title}</Label><br />
                    <Label for="Title">{selectedItem.galleryImageCount} Elementos</Label>
                </div>
                {showEdit ?
                    <FormGroup row>
                        <CardColumns>
                            {images.map((image, index) => (
                                <Card body key={index}>
                                    <CardTitle>{image.fileName}</CardTitle>
                                    <CardImg top src={image.path} style={{ width: '350px' }} alt="Card image cap" />
                                    <Button type="button" onClick={() => handleDeleteImageGallery(image.entityId, image.entityTypeId, image.sequence)}>Delete</Button>
                                    <Row>
                                        <Input type="file" onChange={(event) => handleImageGallerySelected(event, image.entityId, image.entityTypeId, image.sequence)} />
                                        <Button type="button" onClick={() => handleChangeImageGallery(image.entityId, image.entityTypeId, image.sequence)}>Change Image</Button>
                                    </Row>
                                </Card>
                            ))}
                        </CardColumns>
                    </FormGroup> : null}
            </Col>
            <Col xs="2">
                <Button type="button" onClick={handleOnNewGallery}>Add</Button>
                {showEdit ?
                    <Form onSubmit={handleSubmit}>
                        <FormGroup row>
                            <Label for="Title">Nombre de álbum</Label>
                            <Input name="Title" placeholder="Nombre de �lbum" onChange={handleInputChange} value={inputs.Title}></Input>
                        </FormGroup>
                        <GeneralUpload TitleLabel={"Image"} handleOnChangeFiles={handleOnChangeFile} IsMultipe={true} />
                        {inputs.CompanyPostId == 0 ?
                            <div>
                                <FormGroup row>
                                    <Label>Para:</Label>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="DepartmentId">Department</Label>
                                    <SelectCustom selectName="DepartmentId" handleOnChange={handleInputChange} selectValue={inputs.DepartmentId}
                                        method="Department" textField="departmentName" valueField="departmentId" />
                                </FormGroup>

                                <FormGroup row>
                                    <Label for="JobRoleId">Role</Label>
                                    <SelectCustom selectName="JobRoleId" handleOnChange={handleInputChange} selectValue={inputs.JobRoleId}
                                        method="JobRole" textField="name" valueField="jobRoleId" />
                                </FormGroup>
                                <button type="button" onClick={handleOnSearch}>Search</button>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Employee No</th>
                                            <th>Name</th>
                                            <th>Estatus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataUsers.map(item => (
                                            <tr key={item.userProfileId}>
                                                <td>{item.employeeNo}</td>
                                                <td>{item.middleName} {item.lastName} {item.firstName}</td>
                                                <td>{item.active ? 'Active' : 'Inactive'}</td>
                                                <td><Input type="checkbox" name="Selected" onClick={(event) => handleSelectUser(event, item)} /></td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>
                            </div> : null}
                        <input type="submit" value="Submit" />
                        <Button type="button" onClick={handleOnCancel}>Cancel</Button>

                        <FormGroup row>
                            <Label for="Title">Nombre de álbum</Label><br />
                            <Label for="Title">{selectedItem.title}</Label><br />
                            <Label for="Title">Creado el {selectedItem.publishDate}</Label><br />
                            {/* <Label for="Title">Por {selectedItem.writter}</Label><br /> */}
                            <Label for="Title">{selectedItem.galleryImageCount} Elementos</Label><br />
                        </FormGroup>
                    </Form> : null}
            </Col>
        </Row>
    );
}
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Pagination } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'
import './users.scss'
import { userProfileService } from '../../services/backoffice/userProfile.service'
import moment from 'moment';

export function Users() {
    const [tableData, setTableData] = useState({ list: [], totalPages: 1, totalCount: 0, pageIndex: 1, filterBy: "", filter: "", orderBy: "EmployeeNo", ascending: false });
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    useEffect(() => {
        getUsers(1, tableData.filterBy, tableData.filter, tableData.orderBy, tableData.ascending);
    }, []);

    const getUsers = (pageIndex, filterBy, filter, orderBy, ascending) => {
        userProfileService.getAll(pageIndex, 20, filterBy, filter, orderBy, ascending).
            then((result) => {
                if (result.list.length > 0)
                    setTableData({ list: result.list, totalPages: result.totalPages, totalCount: result.totalCount, pageIndex, filterBy, filter, orderBy, ascending });
            });
    }

    const handleSelectPage = (pageIndex) => {
        getUsers(pageIndex, tableData.filterBy, tableData.filter, tableData.orderBy, tableData.ascending);
    }

    const handleFind = () => {
        const filterBy = tableData.filter == "" ? "" : "Name";
        getUsers(tableData.pageIndex, filterBy, tableData.filter, tableData.orderBy, tableData.ascending);
    }

    const handleOrdering = (orderBy) => {
        const ascending = orderBy == tableData.orderBy ? !tableData.ascending : false;
        getUsers(tableData.pageIndex, tableData.filterBy, tableData.filter, orderBy, ascending);
    }

    return (
        <section className="wrapper_users padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt">
                    <h2 className="mb-0 text fnt_medium">Usuarios</h2>
                    <div className="box_actions">
                        {/*<button className="btn secundary mr-2"><i className="far fa-file-excel"></i> Exportar usuarios</button>*/}
                        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle className="btn primary" caret>
                                <i className="fas fa-user-plus"></i> Cargar usuarios
              </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem>
                                    <Link to="/users-details/new">
                                        <i className="far fa-user"></i> Carga indivual
                  </Link>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>
                                    <Link to="/bulk-load">
                                        <i className="fas fa-users"></i> Carga masiva
                  </Link>
                                </DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                    </div>
                </div>
            </div>
            <div className="card shadows p-3 box_tool_user">
                <div className="row  align-items-center">
                    <div className="col-sm-8">
                        <h6 className="mb-0">Total de usuarios: <span className="text fnt_medium">{tableData.totalCount}</span></h6>
                    </div>
                    <div className="col-sm-4">
                        <form className="form_inline">
                            <input type="text" className="form-control" placeholder="Buscar por nombre" onChange={(e) => setTableData({ ...tableData, filter: e.target.value })} />
                            <div className="box_search_inp">
                                <button type="button" className="btn" onClick={handleFind}><i className="fas fa-search"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className=" table-responsive card shadows p-3 mt-4">
                <table className="wrapper_table table table-hover table-sm">
                    <thead>
                        <tr>
                            <th className="text fnt_medium" onClick={() => handleOrdering("EmployeeNo")}>
                                No. Usuario
                                 {tableData.orderBy == "EmployeeNo" && <i className={`fas fa-sort-${tableData.ascending ? 'up' : 'down'}`}></i>}
                            </th>
                            <th className="text fnt_medium" onClick={() => handleOrdering("Name")}>
                                Nombre completo
                                 {tableData.orderBy == "Name" && <i className={`fas fa-sort-${tableData.ascending ? 'up' : 'down'}`}></i>}
                            </th>
                            <th className="text fnt_medium" onClick={() => handleOrdering("Gender")}>
                                Género
                                 {tableData.orderBy == "Gender" && <i className={`fas fa-sort-${tableData.ascending ? 'up' : 'down'}`}></i>}
                            </th>
                            <th className="text fnt_medium text-center" onClick={() => handleOrdering("Age")}>
                                Edad
                                 {tableData.orderBy == "Age" && <i className={`fas fa-sort-${tableData.ascending ? 'down' : 'up'}`}></i>}
                            </th>
                            <th className="text fnt_medium " onClick={() => handleOrdering("Seniority")}>
                                Antigüedad
                                 {tableData.orderBy == "Seniority" && <i className={`fas fa-sort-${tableData.ascending ? 'down' : 'up'}`}></i>}
                            </th>
                            <th className="text fnt_medium" onClick={() => handleOrdering("BranchOffice")}>
                                Sucursal
                                 {tableData.orderBy == "BranchOffice" && <i className={`fas fa-sort-${tableData.ascending ? 'up' : 'down'}`}></i>}
                            </th>
                            <th className="text fnt_medium" onClick={() => handleOrdering("Department")}>
                                Grupo
                                 {tableData.orderBy == "Department" && <i className={`fas fa-sort-${tableData.ascending ? 'up' : 'down'}`}></i>}
                            </th>
                            <th className="text fnt_medium" onClick={() => handleOrdering("JobRole")}>
                                Categoría
                                 {tableData.orderBy == "JobRole" && <i className={`fas fa-sort-${tableData.ascending ? 'up' : 'down'}`}></i>}
                            </th>
                            <th className="text fnt_medium text-center">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData.list.map(user =>
                                <tr>
                                    <td><Link to={"/users-details/" + user.aspNetUserId} className="link_data">{user.employeeNo}</Link></td>
                                    <td><Link to={"/users-details/" + user.aspNetUserId} className="link_data">{user.name}</Link></td>
                                    <td>{user.gender}</td>
                                    <td className="text-center">{moment().diff(user.birthday, 'years')}</td>
                                    <td >{user.seniority}</td>
                                    <td>{user.branchOfficeName}</td>
                                    <td>{user.departmentName}</td>
                                    <td>{user.jobRoleName}</td>
                                    <td className="text-center"><span className={`text-${user.active ? 'success' : 'danger'} text fnt_medium`}>{user.active ? "Activo" : "Inactivo"}</span></td>
                                </tr>)
                        }
                    </tbody>
                </table>
                <Pagination prev last next first size="lg" ellipsis maxButtons={10} boundaryLinks pages={tableData.totalPages} activePage={tableData.pageIndex} onSelect={handleSelectPage} />
            </div>
        </section>
    )
}
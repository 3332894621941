import React, { Fragment, useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Input } from "reactstrap";
import useInputForm from "../../components/CustonHooks/FormHook";
import { GeneralUpload } from "../../components/Helpers/generalUpload";
import { useCaringCommunity } from '../../services/caringCommunity.service';

export function EditCommunity(props) {
    const [image, setImage] = useState(null);
    const [Community, setCommunity] = useState({
        CaringCommunityId: 0,
        Name: "",
        Description: "",
        CompanyId: process.env.REACT_APP_COMPANY,
        Ligue1: "",
        Ligue2: "",
        Image: null,
        Active: false,
    });

    const onSave = () => {
        let formData = new FormData();
        formData.append("CaringCommunityId", inputs.CaringCommunityId);
        formData.append("Name", inputs.Name);
        formData.append("Description", inputs.Description);
        formData.append("CompanyId", inputs.CompanyId);
        formData.append("Ligue1", inputs.Ligue1);
        formData.append("Ligue2", inputs.Ligue2);
        formData.append("Image", inputs.Image.file);
        formData.append("Active", inputs.Active);
        if (inputs.CaringCommunityId && inputs.CaringCommunityId > 0){
            useCaringCommunity
                .updateCommunity(formData)
                .then((result) => {
                    if (props.onSave) props.onSave(result);
                });
        }
        else
        {
            useCaringCommunity
                .createCommunity(formData)
                .then((result) => {
                    if (props.onSave) props.onSave(result);
                });
        }
        
    }

    const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(
        onSave,
        Community
      );
    
    const handleOnCancel = () => {
        if (props.onCancelEdit) props.onCancelEdit();
      };

      
    useEffect(() => {
        setDataFromCurrentCommunity(props.currentCommunity);
    }, [props.currentCommunity])

    const setDataFromCurrentCommunity = async (currentCommunity) => {
        let newC = {};
        if (currentCommunity) {
            let file = await getFileImage(currentCommunity.image);
            newC = {
                CaringCommunityId: currentCommunity.caringCommunityId,
                Name: currentCommunity.name,
                Description: currentCommunity.description,
                CompanyId: currentCommunity.companyId,
                Ligue1: currentCommunity.ligue1,
                Ligue2: currentCommunity.ligue2,
                Image: { url: currentCommunity.image, file: file },
                Active: currentCommunity.active,
            };
            setCommunity(newC);
            setImage({ url: currentCommunity.image, file: file });
        }
        else{
            setCommunity({
                CaringCommunityId: 0,
                Name: "",
                Description: "",
                CompanyId: 871,
                Ligue1: "",
                Ligue2: "",
                Image: null,
                Active: false,
            });
        }
    }

    const handleImageUpload = (files) => {
        if (files.length > 0){
            setInputs({...inputs, Image: { url: URL.createObjectURL(files[0]), file: files[0] }});
            setImage({ url: URL.createObjectURL(files[0]), file: files[0] });
        }
        else {
            setImage(null);
            setInputs({...inputs, Image: null});
        }
    };

    const getFileImage = async (url) => {

        let name = url.substring(url.lastIndexOf('/') + 1).replace("%", " ");
        let file = await fetch(url)
            .then(res => res.blob())
            .then(blob => {
            const file = new File([blob], name, blob)
             return file;
            })
        return file;
    }

    return (
        <Fragment>
            <form onSubmit={handleSubmit}>
                <fieldset className="mt-4 mb-5">
                    <legend className="text fnt_medium">
                        {inputs.CaringCommunityId > 0 ? "Editar comunidad" : "Nuevo"}
                    </legend>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="Name">Título</label>
                                <Input
                                type="text"
                                name="Name"
                                placeholder="Título"
                                onChange={handleInputChange}
                                value={inputs.Name}
                                required
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="Ligue1">Link</label>
                                <Input
                                type="text"
                                name="Ligue1"
                                placeholder="Link página"
                                onChange={handleInputChange}
                                value={inputs.Ligue1}
                                required
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="Ligue2">Link del marketplace</label>
                                <Input
                                type="text"
                                name="Ligue2"
                                placeholder="link marketplace"
                                onChange={handleInputChange}
                                value={inputs.Ligue2}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="Description">Descripción</label>
                                <Input
                                type="textarea"
                                name="Description"
                                placeholder="Descripción"
                                onChange={handleInputChange}
                                value={inputs.Description}
                                required
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <GeneralUpload
                                    TitleLabel={"Subir imagen"}
                                    handleOnChangeFiles={handleImageUpload}
                                    accept="image/*"
                                    required={inputs.CaringCommunityId === 0}
                                />
                            </div>
                        </div>
                        {image ? (
                        <div className="col-md-4">
                            <img src={image.url} style={{ width: "175px" }} />
                        </div>
                        ) : null}
                    </div>
                    <hr />
                    <div>
                        <button
                        className="btn secundary minimum ml-1 mr-1"
                        type="button"
                        onClick={handleOnCancel}
                        >
                        Regresar
                        </button>
                        <input className="btn primary minimum ml-1 mr-1" type="submit" value="Guardar"
                        />
                    </div>
                </fieldset>
            </form>
        </Fragment>
    )
}

import React, { useState } from "react";
import "./ecommerce.scss";
import Moment from "react-moment";
import "moment/locale/es";
import { BoxListEcommerce } from "./boxListEcommerce";
import { SideAdvertising } from "../../components/sideAdvertising/sideAdvertising";
import { AdvertisingSection } from "../../components/advertisingSection/advertisingSection";

export function Ecommerce(props) {
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");

  const AsidePurchaseBox = () => {
    setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");
  };

  return (
    <section className="wrapper_surveys wrapper_ecommerce padd">
      <div className="content_surveys ">
        <div className="row align-items-center mb-4">
          <div className="col">
            <h2 className="mb-0 text fnt_medium ">Tiendas en Línea</h2>
          </div>
          <div className="col text-muted box_more_survey">
            <span className="d-none d-sm-block">
              <Moment locale="es" format="DD [de] MMMM  YYYY">
                {Date.now()}
              </Moment>
            </span>
            <span className="btn_ellipsis" onClick={AsidePurchaseBox}>
              <i className="fas fa-ellipsis-v"></i>
            </span>
          </div>
        </div>
        <AdvertisingSection HolType={5} />
        {/* <div className="content_ttls">
          <div className="minw">
            <h4 className="text fnt_medium ttl mb-3 text-white">
              Bienvenido a las Tiendas en línea
            </h4>
            <p className="text fnt_medium">
              Este es nuestro acceso a las tiendas en línea.
            </p>
          </div>
          <div className="box_img_surveys">
            <img src={PictureHelp} alt="Encuestas" />
          </div>
        </div> */}

        <BoxListEcommerce />

        {/*       <p className="text-muted mt-4 line_height">*/}
        {/*           <small>La entrada a esta tienda es mediante la siguiente <a href="https://appmarketplacefrontmxprod.azurewebsites.net">liga</a></small>*/}
        {/*</p>*/}
      </div>

      <aside className={`aside_lateral trans ${activeAsideSurvey}`}>
        <span className="btn_ellipsis_close" onClick={AsidePurchaseBox}>
          <i className="far fa-window-close"></i>
        </span>
        <SideAdvertising />
      </aside>
    </section>
  );
}

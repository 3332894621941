import React, { useEffect, useState } from 'react';
import useInputForm from '../../../components/CustonHooks/FormHook'
import { companyCustomizeService } from '../../../services/backoffice/companyCustomize.service';

export function CompanyCustomize(props) {

	const [values, setValues] = useState({
		CompanyLogoFile: null,
		CompanyCustomizeId: 0,
		customizeValues: "",
		CompanyId: props.CompanyId,
		CompanyLogo: "",
		PrimaryColor: "",
		SecondaryColor: ""
	});

	const [customize, setCustomize] = useState({
		companyCustomizeId: 0,
		companyId: props.CompanyId,
		customizeValues: "",
		companyLogoFile: null
	});

	const onSave = () => {
		inputs.CompanyId = parseInt(inputs.CompanyId);
		inputs.CompanyCustomizeId = parseInt(inputs.CompanyCustomizeId);
		inputs.CustomizeValues = JSON.stringify({ "CompanyLogo": inputs.CompanyLogo, "PrimaryColor": inputs.PrimaryColor, "SecondaryColor": inputs.SecondaryColor })
		if (customize.companyCustomizeId && customize.companyCustomizeId > 0)
			companyCustomizeService.edit(inputs)
				.then((result) => {
					if (result) {
						window.location.reload();
					}
				});
		else
			companyCustomizeService.create(inputs)
				.then((result) => {
					if (result) {
						window.location.reload();
					}
				});
	};

	useEffect(() => {
		if (props.CompanyId !== 0) {
			companyCustomizeService.getByCompanyId(props.CompanyId).then((result) => {
				if (result && result != null) {
					setCustomize({
						companyCustomizeId: result.companyCustomizeId,
						companyId: result.CompanyId,
						CustomizeValues: result.customizeValues,
						companyLogoFile: null
					});
				}
				else {
					setCustomize({
						companyCustomizeId: 0,
						companyId: props.CompanyId,
						CustomizeValues: null,
						companyLogoFile: null
					});

				}
				var object = result && result.customizeValues != "" ? JSON.parse(result.customizeValues) : null;
				if (object !== null) {
					setValues({
						CompanyCustomizeId: result.companyCustomizeId,
						CompanyId: props.CompanyId,
						CustomizeValues: result.customizeValues,
						CompanyLogoFile: null,
						CompanyLogo: object.CompanyLogo,
						PrimaryColor: object.PrimaryColor,
						SecondaryColor: object.SecondaryColor
					});
				}
				else {
					setValues({
						CompanyCustomizeId: 0,
						CompanyId: props.CompanyId,
						CustomizeValues: 0,
						CompanyLogoFile: null,
						CompanyLogo: null,
						PrimaryColor: null,
						SecondaryColor: null
					});
				}
			});
		}
	}, [props.CompanyId]);

	const { handleSubmit, handleInputChange, handleInputFileChange, inputs } = useInputForm(onSave, values);

	const styleDrop = {
		primary: {
			background: inputs.PrimaryColor
		},
		secondary: {
			background: inputs.SecondaryColor
		}
	}

	return (
		<div className="wrapper_company_customize">
			<form onSubmit={handleSubmit} className="mb-5">
				<fieldset >
					<legend className="text fnt_medium">Personalización</legend>

					<div className="row">
						<div className="col-md-6">
							<input type="hidden" id="CompanyCustomizeId" name="CompanyCustomizeId" onChange={handleInputChange} value={inputs.CompanyCustomizeId} />
							<input type="hidden" id="CompanyId" name="CompanyId" onChange={handleInputChange} value={inputs.CompanyId} />
							<input type="hidden" id="CustomizeValues" name="CustomizeValues" onChange={handleInputChange} value={inputs.CustomizeValues} />
							<div className="input_div">
								<div className="drop_color" style={styleDrop.primary}></div>
								<div className="form-group">
									<label htmlFor="PrimaryColor">Color Primario</label>
									<input type="text" id="PrimaryColor" name="PrimaryColor" className="form-control" onChange={handleInputChange} value={inputs.PrimaryColor} required />
								</div>
							</div>

						</div>
						<div className="col-md-6">
							<div className="input_div">
								<div className="drop_color" style={styleDrop.secondary}></div>
								<div className="form-group">
									<label htmlFor="SecondaryColor">Color Secundario</label>
									<input type="text" id="SecondaryColor" name="SecondaryColor" className="form-control" onChange={handleInputChange} value={inputs.SecondaryColor} required />
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<label htmlFor="CompanyLogoFile">Logotipo de la empresa</label>
							<div className="">
								<input type="file" id="CompanyLogoFile" name="CompanyLogoFile" onChange={handleInputFileChange} />
							</div>
						</div>
					</div>
					<hr />
					<div>
						<button className="btn primary minimum" type="submit">Guardar</button>
					</div>
				</fieldset>
			</form>
		</div>
	);
}   
